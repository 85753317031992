
import Channel from "@/interfaces/Channel";
import { DrawersModule } from "@/store/modules/drawers";
import { LastSeenServerChannelsModule } from "@/store/modules/lastSeenServerChannel";
import { PopoutsModule } from "@/store/modules/popouts";
import { emojiURL } from "@/utils/emojiParser";
import { PropType } from "vue";
import { defineComponent } from "vue";
import { ChannelsModule } from "@/store/modules/channels";
import ChannelTemplate from "./ChannelTemplate.vue";
export default defineComponent({
  name: "CategoryTemplate",
  components: { ChannelTemplate },
  props: {
    category: {
      type: Object as PropType<Channel>,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
      collapsed: false,
    };
  },
  computed: {
    selectedDetails(): any {
      return {
        server_id: this.$route.params.server_id,
        channel_id: this.$route.params.channel_id,
      };
    },
    channelNotifications(): any {
      return LastSeenServerChannelsModule.serverNotifications(this.selectedDetails.server_id, this.category.channelId)
    },
    channels(): any[] {
      if (!this.selectedDetails.server_id) return [];
      return ChannelsModule.sortedServerChannels(
        this.selectedDetails.server_id
      ).filter((channel) => channel.categoryId === this.category.channelId);
    },
    isChannelSelected(): any {
      return this.channels.find(
        (c) => c.channelId === this.selectedDetails.channel_id
      );
    },
    iconURL(): any {
      const icon = this.category.icon;
      if (!icon) return null;
      const isCustom = icon.startsWith("g_") || icon.startsWith("c_");
      const isGif = icon.startsWith("g_");
      const customEmojiID = icon.split("_")[1];
      return emojiURL(isCustom ? customEmojiID : icon, {
        animated: this.hover,
        isCustom,
        isGif,
      });
    },
    channelStyle(): any {
      return {
        "--icon-url": this.iconURL && `url("${this.iconURL}")`,
      };
    },
  },
  methods: {
    closeDrawer() {
      DrawersModule.SetLeftDrawer(false);
    },
    showContext(event: any) {
      PopoutsModule.ShowPopout({
        id: "context",
        component: "ChannelContextMenu",
        key: this.category.server_id + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          server_id: this.category.server_id,
          channelId: this.category.channelId,
        },
      });
    },
  },
});
